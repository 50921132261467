<template>
    <div>
        <div>
            <form-document-a-c-a-e/>
        </div>

        <div>
            <fields-info-a-c-a-e />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import formDocumentACAE from "@/views/gei/children_installations/form/tabDocumentACAE/components/formDocumentACAE"
import {mapFields} from "vuex-map-fields"
import FieldsInfoACAE from "@/views/gei/children_installations/form/tabDocumentACAE/components/fieldsInfoACAE.vue"

export default {
    name: "FormGeneralDataAndRisksView",
    components: {
        FieldsInfoACAE,
        formDocumentACAE
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationDocuments', []),
        ...mapFields({
            child_installation: 'childrenInstallation.child_installation',

        }),
    },
    created() {

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>