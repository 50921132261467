<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Documentación, proyecto y manuales</h5>
              <div>
                <b-button v-if="can('delete_gei_children_orders')" variant="outline-info mr-2" @click="addRowMaster">
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                      variant="success"
                      class="cursor-pointer "
                  />
                    Añadir registro en todas las instalaciones hijas
                </b-button>
                <b-button v-if="can('delete_gei_children_projects_manuals')" variant="outline-success" @click="addRow">
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                      variant="success"
                      class="cursor-pointer "
                  />
                    Añadir registro en esta instalación
                </b-button>
              </div>

            </div>
            <form-base-document
                :documents-state-data="documents_manualproject"
                :documents-columns="columns"
                :documents-route="documents_by_route_manualproject"
                :context="'document-manual-project'"
                :create-permission="can('create_gei_children_projects_manuals')"
                :delete-permission="can('delete_gei_children_projects_manuals')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocument from "@/views/gei/children_installations/form/tabManualProject/components/base/formBaseDocument"
import {mapFields} from "vuex-map-fields"

export default {
    name: "FormContractProviderDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument

    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'document_date', label: 'Fecha Doc', sortable: false},
                {key: 'document_type_id', label: 'Tipo Documento', sortable: false},
                {key: 'industry_ownership_id', label: 'Titular en Industria', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_manualproject: 'childrenInstallationDocuments.documents_manualproject',
            documents_by_route_manualproject: 'childrenInstallationDocuments.documents_by_route_manualproject',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {

        this.context = 'document-manual-project'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                document_date: null,
                document_type_id: null,
                industry_ownership_id: null,
                route: this.child_installation.master_installation.installation_code + "/" + this.child_installation.gei_code + "/PROYECTO_INSTALACION",
                children_installation_id: this.child_installation.id,
                observations: null,
                options: null,
                master: 0,
            }

            this.documents_manualproject.unshift(newRow)
            this.documents_manualproject.editedRow = newRow
        },
        addRowMaster(){
          const newRow = {
            document_date: null,
            document_type_id: null,
            industry_ownership_id: null,
            route: this.child_installation.master_installation.installation_code + "/" + this.child_installation.gei_code + "/PROYECTO_INSTALACION",
            children_installation_id: this.child_installation.id,
            observations: null,
            options: null,
            master: 1,
          }

          this.documents_manualproject.unshift(newRow)
          this.documents_manualproject.editedRow = newRow
        }
    }

}
</script>