<template>
    <div>
        <b-form-group label="Expediente PRL" label-for="expediente">
            <b-form-input v-model="expedientPRL" placeholder="Expediente PRL"
                          autocomplete="off" :disabled="disabled" :class="validation ? 'is-invalid': ''"/>

            <small v-if="validation" class="text-danger">
                {{ validation }}
            </small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        expedientPRL: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localExpedientPRL: this.expedientPRL,
        }
    },
    watch: {
        expedientPRL(newVal) {
            this.localExpedientPRL = newVal
            this.$emit('update', { expedient_prl: this.localExpedientPRL })
        },
    },
}
</script>
