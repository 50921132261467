<template>
    <div>
        <b-card title="" border-variant="primary">
            <form-base-document-insitu
                :documents-state-data="documents_maintenance_insitu"
                :documents-columns="columns"
                :documents-route="documents_by_route_maintenance_insitu"
                :context="'document-insitu'"
                :create-permission="can('create_gei_children_maintenance_acae_in_situ')"
                :delete-permission="can('delete_gei_children_maintenance_acae_in_situ')"
            />
        </b-card>
    </div>
</template>

<script>
import {BCard} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocumentInsitu
    from "@/views/gei/children_installations/form/tabMaintenance/components/base/formBaseInSitu"

import {mapFields} from "vuex-map-fields"

export default {
    name: "FormContractProviderDocuments",
    components: {
        BCard,
        formBaseDocumentInsitu
    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'effective_date', label: 'Fecha Realización', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_maintenance_insitu: 'childrenInstallationDocuments.documents_maintenance_insitu',
            documents_by_route_maintenance_insitu: 'childrenInstallationDocuments.documents_by_route_maintenance_insitu',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {
       this.context = 'document-insitu'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }

}
</script>