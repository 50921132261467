<template>
    <div>
        <b-form-group label="Observaciones concurrencia" label-for="observations">
            <b-form-textarea id="observations" v-model="competitionObservations" rows="4" :disabled="disabled"
                             placeholder="Observaciones" autocomplete="off"
                             :class="validation ? 'is-invalid': ''"/>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormTextarea} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormTextarea,
    },
    props: {
        competitionObservations: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localCompetitionObservations: this.competitionObservations,
        }
    },
    watch: {
        competitionObservations(newVal) {
            this.localCompetitionObservations = newVal
            this.$emit('update', { competition_observations: this.localCompetitionObservations })
        },
    },
}
</script>
