<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Plan especifico de prevención</h5>
                <b-button v-if="can('delete_gei_children_contract_ee_provider')"
                    variant="outline-success"
                    @click="addRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "
                    />
                </b-button>
            </div>

            <form-base-document
                :documents-state-data="documents_specific_prevention"
                :documents-columns="columns"
                :documents-route="documents_by_route_specific_prevention"
                :context="'specific-prevention'"
                :create-permission="can('create_gei_children_specific_plan')"
                :delete-permission="can('delete_gei_children_specific_plan')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"

import formBaseDocument from "@/views/gei/children_installations/form/tabDocuments/components/base/formBaseDocument"
import {mapFields} from "vuex-map-fields"


export default {
    name: "FormContractProviderDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument

    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'received_date', label: 'Fecha Recepción', sortable: false},
                {key: 'exam_date', label: 'Fecha Examin.', sortable: false},
                {key: 'provider_id', label: 'Proveedor', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_specific_prevention: 'childrenInstallationDocuments.documents_specific_prevention',
            documents_by_route_specific_prevention: 'childrenInstallationDocuments.documents_by_route_specific_prevention',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {
       this.context = 'specific-prevention'
      //  this.getDocumentTypes()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                received_date: null,
                exam_date: null,
                provider_id: null,
                route: this.child_installation.master_installation.installation_code +"/"+ this.child_installation.gei_code + "/PLAN_ESPECIFICO_PROVEEDOR",
                observations: null,
                options: null,
                children_installation_id: this.child_installation.id,
            }

            this.documents_specific_prevention.unshift(newRow)
            this.documents_specific_prevention.editedRow = newRow
        },
    }

}
</script>