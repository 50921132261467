<template>
    <div>
        <b-card title="" border-variant="primary">
            <b-row>
                <b-col xl="12">
                    <competition-activities-checkbox :competition-activities="child_installation.competition_activities"
                                                     @update="handleUpdateFields"/>
                </b-col>
            </b-row>

            <b-row>
                <b-col xl="4">
                    <vehicles-competition-checkbox :vehicles-competition="child_installation.vehicles_competition"
                                                   @update="handleUpdateFields"/>
                </b-col>

                <b-col xl="4">
                    <people-traffic-competition-checkbox :people-traffic-competition="child_installation.people_traffic_competition"
                                                         @update="handleUpdateFields"/>
                </b-col>

                <b-col xl="4">
                    <ins-client-e-e-checkbox :ins-client-ee="child_installation.ins_client_ee"
                                             @update="handleUpdateFields"/>
                </b-col>
            </b-row>

            <b-row>
                <b-col xl="4">
                    <work-maintained-competition-checkbox :work-maintained-competition="child_installation.work_maintained_competition"
                                                          @update="handleUpdateFields"/>
                </b-col>

                <b-col xl="4">

                    <other-traffic-competition-checkbox :other-traffic-competition="child_installation.other_traffic_competition"
                                                        @update="handleUpdateFields"/>
                </b-col>

                <b-col xl="4">
                    <ins-completed-e-e-checkbox :ins_completed_ee="child_installation.ins_completed_ee"
                                                 @update="handleUpdateFields"/>
                </b-col>
            </b-row>

            <b-row>
                <b-col offset-xl="8" xl="4">
                    <access-control-e-e-checkbox :access-control-ee="child_installation.access_control_ee"
                                                 @update="handleUpdateFields"/>
                </b-col>
            </b-row>

            <b-row>
                <b-col xl="12">
                    <competition-observations-textarea :competition-observations="child_installation.competition_observations"
                                            @update="handleUpdateFields"/>
                </b-col>
            </b-row>

            <b-col v-if="edit" class="d-flex justify-content-end">
                <b-button v-if="can('delete_gei_children_documents_acae')" class="col-sm-2" variant="outline-success" block @click="updateInstallation()">
                    Guardar
                </b-button>
            </b-col>
        </b-card>
    </div>
</template>

<script>
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {BButton, BCard, BCol, BRow} from "bootstrap-vue"
import {mapFields} from "vuex-map-fields"
import competitionActivitiesCheckbox from "./fields/CompetitionActivitiesCheckbox.vue"
import vehiclesCompetitionCheckbox from "./fields/VehiclesCompetitionCheckbox.vue"
import WorkMaintainedCompetitionCheckbox from "./fields/WorkMaintainedCompetitionCheckbox.vue"
import PeopleTrafficCompetitionCheckbox from "./fields/PeopleTrafficCompetitionCheckbox.vue"
import OtherTrafficCompetitionCheckbox from "./fields/OtherTrafficCompetitionCheckbox.vue"
import InsClientEECheckbox from "./fields/InsClientEECheckbox.vue"
import AccessControlEECheckbox from "./fields/AccessControlEECheckbox.vue"
import competitionObservationsTextarea from "./fields/CompetitionObservationsTextarea.vue"
import InsCompletedEECheckbox from "./fields/InsCompletedEECheckbox.vue"
import helper from "@/resources/helpers/helpers"

export default {
    name: "FieldsInfoACAE",
    components: {
        BButton,
        BRow,
        BCol,
        BCard,

        competitionActivitiesCheckbox,
        vehiclesCompetitionCheckbox,
        WorkMaintainedCompetitionCheckbox,
        PeopleTrafficCompetitionCheckbox,
        OtherTrafficCompetitionCheckbox,
        InsClientEECheckbox,
        InsCompletedEECheckbox,
        AccessControlEECheckbox,
        competitionObservationsTextarea
    },
    data() {
        return {
            SpanishLocale,
            showLoading: false,
        }
    },
    computed: {
        ...mapFields({
            child_installation: 'childrenInstallation.child_installation',
        }),
        edit(){
            return !!this.$route.params.id
        }
    },
    created() {

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        handleUpdateFields(updatedFields) {
            this.child_installation = {...this.child_installation, ...updatedFields}
        },
        updateInstallation() {
            this.$store.dispatch('childrenInstallation/updateChildrenInstallation', this.child_installation)
                .then(response => {
                    helper.showToast('Instalación hija editada correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>