<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Documento ACAE</h5>

                <div>
                    <b-button v-if="can('delete_gei_children_documents_acae')"
                              variant="outline-info mr-2"
                              @click="addRowMaster"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                            variant="success"
                            class="cursor-pointer "
                        />
                        Añadir registro en todas las instalaciones hijas
                    </b-button>

                    <b-button v-if="can('delete_gei_children_documents_acae')"
                              variant="outline-success"
                              @click="addRow"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                            variant="success"
                            class="cursor-pointer "
                        />
                        Añadir registro en esta instalación
                    </b-button>
                </div>
            </div>
            <form-base-document
                :documents-state-data="documents_acae"
                :documents-columns="columns"
                :documents-route="documents_by_route_acae"
                :context="'document-acae'"
                :create-permission="can('create_gei_children_documents_acae')"
                :delete-permission="can('delete_gei_children_documents_acae')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocument from "@/views/gei/children_installations/form/tabDocumentACAE/components/base/formBaseDocument"
import {mapFields} from "vuex-map-fields"

export default {
    name: "FormContractProviderDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument

    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'effective_date', label: 'Fecha Celebración', sortable: false},
                {key: 'ACAE_type_id', label: 'Tipo ACAE', sortable: false},
                {key: 'provider_id', label: 'Proveedor', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_acae: 'childrenInstallationDocuments.documents_acae',
            documents_by_route_acae: 'childrenInstallationDocuments.documents_by_route_acae',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {
       this.context = 'document-acae'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                effective_date: null,
                acae_type_id: null,
                provider_id: null,
                route: this.child_installation.master_installation.installation_code +"/"+ this.child_installation.gei_code + "/ACTA_COORDINACION",
                observations: null,
                options: null,
                children_installation_id: this.child_installation.id,
                master: 0,
            }

            this.documents_acae.unshift(newRow)
            this.documents_acae.editedRow = newRow
        },
        addRowMaster(){
            const newRow = {
                effective_date: null,
                acae_type_id: null,
                provider_id: null,
                route: this.child_installation.master_installation.installation_code +"/"+ this.child_installation.gei_code + "/ACTA_COORDINACION",
                observations: null,
                options: null,
                children_installation_id: this.child_installation.id,
                master: 1,
            }

            this.documents_acae.unshift(newRow)
            this.documents_acae.editedRow = newRow
        }
    }

}
</script>