<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Contrato EE Proveedor</h5>
                <b-button v-if="can('delete_gei_children_contract_ee_provider')"
                    variant="outline-success"
                    @click="addRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "
                    />
                </b-button>
            </div>

            <form-base-document
                :documents-state-data="documents_provider_contract_e_e"
                :documents-columns="columns"
                :documents-route="documents_by_route_provider_contract_e_e"
                :context="'provider-contract-ee'"
                :create-permission="can('create_gei_children_contract_ee_provider')"
                :delete-permission="can('delete_gei_children_contract_ee_provider')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"

import formBaseDocument from "@/views/gei/children_installations/form/tabDocuments/components/base/formBaseDocument"
import {mapFields} from "vuex-map-fields"


export default {
    name: "FormContractProviderDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument

    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'contract_date', label: 'Fecha Contrato', sortable: false},
                {key: 'scope', label: 'Alcance', sortable: false},
                {key: 'start_date', label: 'Fecha Inicio', sortable: false},
                {key: 'end_date', label: 'Fecha Fin', sortable: false, thStyle: { width: "50px" }},
                {key: 'document_type_id', label: 'Tipo Documento', sortable: false},
                {key: 'provider_id', label: 'Proveedor', sortable: false},
                {key: 'obralia_exp', label: 'Exp. Obralia', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_provider_contract_e_e: 'childrenInstallationDocuments.documents_provider_contract_e_e',
            documents_by_route_provider_contract_e_e: 'childrenInstallationDocuments.documents_by_route_provider_contract_e_e',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {
       this.context = 'provider-contract-ee'
        this.getDocumentTypes()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                contract_date: null,
                scope: null,
                date_start: null,
                date_end: null,
                document_type_id: null,
                provider_id: null,
                obralia_exp: null,
                route: this.child_installation.master_installation.installation_code +"/"+ this.child_installation.gei_code + "/CONTRATO_PROVEEDOR",
                observations: null,
                options: null,
                children_installation_id: this.child_installation.id,
            }

            this.documents_provider_contract_e_e.unshift(newRow)
            this.documents_provider_contract_e_e.editedRow = newRow
        },
        getDocumentTypes() {
            this.showLoading = true

            this.$store.dispatch('documentsTypes/getAllDocumentTypes')
            .then(response => {

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    }

}
</script>