<template>
    <div>
        <h5 class="mb-1">Permiso Trabajo ATEX</h5>
        <b-table
            :items="documentsStateData"
            :fields="documentsColumns"
            responsive
            sticky-header
            primary-key="id"
            show-empty
            striped
            editable
            empty-text="No hay registros"
        >

            <template #cell(effective_date)="{ item }">
                <div :style="{ backgroundColor: item.has_documents ? 'rgba(0, 167, 225, 0.2)' : '' }">
                    <template v-if="isRowEditable(item)">
                        <flat-pickr
                            v-model="item.effective_date"
                            class="form-control"
                            name="effective_date"
                            autocomplete="off"
                            :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y', altInput: true, altFormat: 'd-m-Y' }"
                        />
                    </template>
                    <template v-else>
                        {{ item.effective_date }}
                    </template>
                </div>
            </template>

            <template #cell(made_by)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-input v-model="item.made_by" autocomplete="off"/>
                </template>
                <template v-else>
                    {{ item.made_by }}
                </template>
            </template>

            <template #cell(observations)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-textarea v-model="item.observations" autocomplete="off"/>
                </template>
                <template v-else>
                    {{ item.observations }}
                </template>
            </template>

            <template #cell(options)="data">
                <div class="d-flex align-items-center">
                    <feather-icon
                        v-if="deletePermission"
                        :id="`record-row-${data.item.id}-edit-icon`"
                        :icon="getButtonIcon(data.item)"
                        class="cursor-pointer mr-1"
                        :class="getButtonVariant(data.item)"
                        size="16"
                        @click="editRow(data.item)"
                    />

                    <feather-icon
                        v-if="data.item.id"
                        :id="`record-row-${data.item.id}-see-icon`"
                        icon="SearchIcon"
                        size="16"
                        variant="warning"
                        class="cursor-pointer mr-1 text-blue"
                        @click="seeDocumentsList(data.item.id)"
                    />
                    <feather-icon
                        v-if="deletePermission"
                        :id="`record-row-${data.item.id}-delete-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer text-red"
                        @click="deleteRecord(data.item.id, data.index )"
                    />

                </div>
            </template>

        </b-table>
        <b-modal v-model="showModal" hide-footer title="Documentos" size="lg">
            <b-table
                ref="refUserListTable"
                :items="documentsRoute"
                responsive
                :fields="columnsDocuments"
                primary-key="id"
                show-empty
                sticky-header
                striped
                editable
                empty-text="No hay registros"
                class="table-bordered "
                :key-field="'index'"
            >
                <template #cell(options)="data">
                    <div class="d-flex align-items-center justify-content-center">
                        <a :href="baseUrl+data.item.path" target="_blank">
                            <feather-icon
                                v-if="createPermission"
                                :id="`record-row-${data.item.id}-see-icon`"
                                icon="EyeIcon"
                                size="16"
                                variant="warning"
                                class="cursor-pointer mr-1 text-blue"
                            />
                        </a>
                        <feather-icon
                            v-if="deletePermission"
                            :id="`record-row-${data.item.id}-delete-icon`"
                            icon="TrashIcon"
                            size="16"
                            class="cursor-pointer text-red"
                            @click="deleteDocument(data.item.id, data.item.path)"
                        />
                    </div>
                </template>
            </b-table>
            <template>
                <div v-if="currentDocumentId>0">
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <vue-dropzone
                            id="dropzone"
                            ref="dropzone"
                            :options="dropzoneOptions"
                            :use-custom-slot="true"
                            @vdropzone-complete="complete">
                            <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">Arrastrar y soltar para subir archivo</h3>
                                <div class="subtitle">...o hacer clic para seleccionar un archivo de tu ordenador
                                </div>
                            </div>
                        </vue-dropzone>
                    </b-overlay>
                </div>
            </template>

            <b-button class="mt-3" variant="outline-danger" block @click="closeModal()">Cerrar</b-button>
        </b-modal>
    </div>
</template>

<script>
import {BTable, BFormInput, BModal, BButton, BOverlay, BFormTextarea} from 'bootstrap-vue'
import {baseUrl} from "@/constants/app"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import authService from "@/services/auth/authService"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import flatPickr from "vue-flatpickr-component"
import VueDropzone from "vue2-dropzone"
import helper from "@/resources/helpers/helpers"
import Swal from "sweetalert2"
import {mapState} from "vuex"
import {mapFields} from "vuex-map-fields"
import {createPopper} from '@popperjs/core'

export default {
    components: {
        BTable,
        BFormInput,
        BModal,
        BButton,
        BOverlay,
        flatPickr,
        BFormTextarea,
        VueDropzone: VueDropzone,
    },
    props: {
        documentsStateData: {
            type: Array,
            required: true
        },
        documentsColumns: {
            type: Array,
            required: true
        },
        documentsRoute: {
            type: Array,
            required: true
        },
        context: {
            type: String,
            required: true
        },
        createPermission: {
            type: Boolean,
        },
        deletePermission: {
            type: Boolean,
        }
    },

    data() {
        return {
            baseUrl,
            currentDocumentId: null,
            selectedRow: null,
            placement: 'top',

            showModal: false,
            SpanishLocale,
            edit: false,
            showLoading: false,
            urlDocs: null,
            editedRow: null,
            columnsDocuments: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'name', label: 'Archivo', sortable: false},
            ],
            dropzoneOptions: {},

        }
    },
    computed: {
        ...mapState('providers', []),
        ...mapFields({
            providers: 'providers.providers',
        }),
    },
    mounted() {
        console.log(this.$root.$refs.tabMaintenanceForm)
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        withPopper(dropdownList, component, {width}) {
            dropdownList.style.width = width
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({state}) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                ],
            })

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy()
        },
        isRowEditable(item) {
            return item === this.editedRow || item === this.documentsStateData.editedRow || !item.id
        },
        editRow(item) {
            if (this.isRowEditable(item)) {
                this.editedRow = null
                this.updateRecord(item)

            } else {
                this.editedRow = item
            }
        },
        getButtonVariant(item) {
            return this.isRowEditable(item) ? 'text-green' : 'info'
        },
        getButtonIcon(item) {
          //  return 'CheckIcon'
            return this.isRowEditable(item) ? 'CheckIcon' : 'EditIcon'
        },
        getButtonText(item) {
            return this.isRowEditable(item) ? 'Editar' : 'Guardar'
        },
        closeModal() {
            this.showModal = false
            this.$root.$refs.childrenInstallationForm.getChildInstallation()
        },
        seeDocumentsList(id) {
            this.currentDocumentId = id
            this.updateDropzoneOptions()
            this.$store.dispatch('childrenInstallationDocuments/getDocumentsList', {id: id, context: this.context})
            .then(response => {
                this.showLoading = false
                this.showModal = true
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        updateDropzoneOptions() {
            this.dropzoneOptions = {
                url: baseUrl + 'api/gei/children-installation/documents/' + this.context + '/upload-documents/' + this.currentDocumentId,
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                autoProcessQueue: true,
                addRemoveLinks: true,
                headers: {"Authorization": 'Bearer ' + authService.accessToken()},
            }
        },
        complete: function (file) {
            this.showLoading = true
            this.seeDocumentsList(this.currentDocumentId)
            setTimeout(() => {
                this.$refs.dropzone.removeAllFiles()
            }, 2000)
        },
        updateRecord(item) {
            item.context = this.context

          /*  if(item.id == 'undefined' && this.documentsStateData.id == 'undefined'){
             //   item.id = null
            }
*/
            if (item.id) {
                this.$store.dispatch('childrenInstallationDocuments/updateRecordGeneralData', item)
                .then(() => {

                    this.showLoading = false
                    this.$root.$refs.childrenInstallationForm.getChildInstallation()

                    helper.showToast('Registro actualizado correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    this.showLoading = false
                    helper.showToast('Error en editar al actualizar registro', 'AlertOctagonIcon', 'danger')
                })
            } else {
                this.$store.dispatch('childrenInstallationDocuments/createRecordGeneralData', item)
                .then(response => {
                    this.showLoading = false

                    this.$root.$refs.childrenInstallationForm.getChildInstallation()
                    this.$root.$refs.tabMaintenanceForm.setAtex(response)

                    helper.showToast('Registro creado correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    this.showLoading = false
                    helper.showToast('Error en editar el registro', 'AlertOctagonIcon', 'danger')
                })
            }
        },
        deleteRecord(id, index) {
            if (id) {
                Swal.fire({
                    title: "Estás seguro que quieres borrar el registro?",
                    text: "Una vez borrado no se podrá recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "¡Sí, borralo!",
                    cancelButtonText: "Cancelar",
                }).then(result => {
                    if (result.value) {
                        this.showLoading = true
                        this.$store.dispatch('childrenInstallationDocuments/deleteRecord', {
                            id: id,
                            context: this.context
                        })
                        .then(response => {
                            this.showLoading = false
                            helper.showToast('Registro eliminado correctamente!', 'CheckIcon', 'success')
                            this.$root.$refs.childrenInstallationForm.getChildInstallation()
                            this.documentsStateData.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                    }
                })
            } else {
                this.documentsStateData.splice(index, 1)
            }
        },
        deleteDocument(recordId, path) {
            if (recordId) {
                Swal.fire({
                    title: "Estás seguro que quieres borrar el archivo?",
                    text: "Una vez borrado no se podrá recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "¡Sí, borralo!",
                    cancelButtonText: "Cancelar",
                }).then(result => {
                    if (result.value) {
                        this.showLoading = true
                        this.$store.dispatch('childrenInstallationDocuments/deleteDocument', {
                            id: recordId,
                            path: path,
                            context: this.context
                        })
                        .then(response => {
                          this.showLoading = false
                          this.$root.$refs.childrenInstallationForm.getChildInstallation()
                          helper.showToast('Registro eliminado correctamente!', 'CheckIcon', 'success')
                          this.seeDocumentsList(this.currentDocumentId)
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                    }
                })
            }
        },

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir] .modal .modal-header .close {
    box-shadow: none;
    transform: unset;
    background: none;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>