<template>
    <div>
        <b-form-group label-for="dias" class="mt-2">
            <b-form-group class="form-check form-check-inline">
                <b-form-checkbox v-model="accessControlEe" :value="1" :disabled="disabled">
                    ¿Control acceso EE?
                </b-form-checkbox>
            </b-form-group>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormCheckbox,
    },
    props: {
        accessControlEe: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localAccessControlEE: this.accessControlEe,
        }
    },
    watch: {
        accessControlEe(newVal) {
            console.log(newVal)
            this.localAccessControlEE = newVal
            this.$emit('update', { access_control_ee: this.localAccessControlEE })
        },
    },
}
</script>
