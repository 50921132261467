<template>
    <div>
        <b-card border-variant="primary" class="p-0">
            <h5 class="mb-0">Generar OCAs</h5>
            <b-row sm="12">
                <b-col sm="2">
                    <b-form-group label-for="period" class="mt-2">
                        <label>Tipo</label>
                        <v-select
                            v-model="maintenance_config.periodicity"
                            class="pt-0" label="name" name="name"
                            :reduce="(option) => option.value"
                            :options="tipos"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label-for="period" class="mt-2">
                        <label>Periodicidad</label>
                        <v-select
                            v-model="maintenance_config.tipo"
                            class="pt-0" label="name" name="name"
                            :reduce="(option) => option.value"
                            :options="maintenancePeriods"
                        />
                    </b-form-group>
                </b-col>

                <b-col v-if="can('create_gei_children_ocas')" sm="2" class="mt-3">
                    <b-form-group label-for="button">
                        <b-button variant="outline-info" block @click="generateMaintenance()">Generar</b-button>
                    </b-form-group>
                </b-col>
                <b-col v-if="can('delete_gei_children_ocas')" sm="2">
                    <b-form-group label-for="button" class="mt-3">
                        <b-button variant="outline-danger" block @click="generateMaintenance()">Eliminar OCAs generadas</b-button>
                    </b-form-group>
                </b-col>

            </b-row>
        </b-card>
    </div>
</template>

<script>
import {BFormGroup, BCard, BCol, BRow, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {mapFields} from "vuex-map-fields"
import vSelect from "vue-select"
import {maintenancePeriods} from "@/constants/childrenDocuments"
import flatPickr from "vue-flatpickr-component"

export default {
    name: "FormView",
    components: {
        BCol,
        BCard,
        BRow,
        BFormGroup,
        BButton,

        vSelect
    },

    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
            isDisabled: true,
            maintenance_config: {},

            maintenancePeriods,
        }
    },
    computed: {
        ...mapState('maintenanceTaskTypes', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            maintenance_task_types: 'maintenanceTaskTypes.maintenance_task_types',
            providers: 'providers.providers',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    methods:{
        can(permission) {
            return this.$store.getters.can(permission)
        },
        generateMaintenance(){
            this.maintenance_config.children_installation_id = this.child_installation.id
            this.maintenance_config.route = this.child_installation.master_installation.installation_code +"/"+ this.child_installation.gei_code + "/OCA",
            this.$root.$refs.tabMaintenanceForm.updateRecord(this.maintenance_config)
        },
    }

}
</script>