<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">OCAs</h5>
              <b-button
                  v-if="can('delete_gei_children_ocas')"
                        variant="outline-success"
                        @click="addRow"
                        class="btn btn-outline-success"
              >
                <feather-icon
                    icon="PlusIcon"
                    size="16"
                    variant="success"
                    class="cursor-pointer "
                />
              </b-button>
            </div>
            <form-base-document
                :documents-state-data="documents_oca"
                :documents-columns="columns"
                :documents-route="documents_by_route_oca"
                :context="'document-oca'"
                :create-permission="can('create_gei_children_ocas')"
                :delete-permission="can('delete_gei_children_ocas')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocument from "@/views/gei/children_installations/form/tabOca/components/base/formBaseDocument"
import formAddOca from "@/views/gei/children_installations/form/tabOca/components/form/formAddOca"
import {mapFields} from "vuex-map-fields"
import {ocasPeriodicity, ocasType} from "@/constants/constants"

export default {
    name: "FormOca",
    components: {
        BCard,
        formBaseDocument,
    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'color', label: 'Estado', sortable: false},
                {key: 'expected_date', label: 'Fecha Prevista', sortable: false},
                {key: 'effective_date', label: 'Fecha Realización', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
                {key: 'passed', label: 'Pasada', sortable: false},
                {key: 'defects', label: 'Defectos', sortable: false},
                {key: 'acting', label: 'Actuante', sortable: false},
                {key: 'periodicity', label: 'Periodicidad', sortable: false},
                {key: 'performance', label: 'Actuación', sortable: false},
                {key: 'periodicity_observations', label: 'Observaciones a la actuación', sortable: false},
                {key: 'inspection.type', label: 'Tipo', sortable: false},
            ],


        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_oca: 'childrenInstallationDocuments.documents_oca',
            documents_by_route_oca: 'childrenInstallationDocuments.documents_by_route_oca',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {
        this.context = 'document-oca'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                start_date: null,
                end_date: null,
                provider_id: null,
                order_number: null,
                request_date: null,
                order_maintenance_type_id: null,
                order_maintenance_sub_type_id: null,
                amount: null,
                route: this.child_installation.master_installation.installation_code + "/" + this.child_installation.gei_code + "/OCA",
                children_installation_id: this.child_installation.id,
                observations: null,
                options: null,
            }

            this.documents_oca.unshift(newRow)
            this.documents_oca.editedRow = newRow
        },
    }

}
</script>