<template>
    <div>
        <b-form-group label="Tipologia" label-for="typology">
            <v-select
                v-model="typologyId"
                class="pt-0" label="name" name="name"
                :reduce="(option) => option.id"
                :options="typologiesList"
                :disabled="disabled"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>
<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        typologyId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            typologiesList: [],
            localTypologyId: this.typologyId,
        }
    },
    watch: {
        typologyId(newVal) {
            this.localTypologyId = newVal
            this.$emit('update', { typology_id: this.localTypologyId })
        },
    },
    mounted() {
        this.getTypologies()
    },
    methods:{
        getTypologies() {
            this.showLoading = true

            this.$store.dispatch('typologies/getAllTypologies')
            .then(response => {
                this.typologiesList = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },

}
</script>
