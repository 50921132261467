<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">G. Pedidos</h5>
                <div>
                  <b-button v-if="can('delete_gei_children_orders')" variant="outline-info mr-2" @click="addRowMaster">
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "
                    />
                    Añadir registro en todas las instalaciones hijas
                  </b-button>
                  <b-button v-if="can('delete_gei_children_orders')" variant="outline-success" @click="addRow">
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "
                    />
                    Añadir registro en esta instalación
                  </b-button>
                </div>
            </div>
            <form-base-document
                :documents-state-data="documents_order"
                :documents-columns="columns"
                :documents-route="documents_by_route_order"
                :context="'document-order'"
                :create-permission="can('create_gei_children_orders')"
                :delete-permission="can('delete_gei_children_orders')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocument from "@/views/gei/children_installations/form/tabOrder/components/base/formBaseDocument"
import {mapFields} from "vuex-map-fields"

export default {
    name: "FormOrder",
    components: {
        BButton,
        BCard,
        formBaseDocument

    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'start_date', label: 'Fecha Inicio', sortable: false},
                {key: 'end_date', label: 'Fecha Fin', sortable: false},
                {key: 'provider_id', label: 'Proveedor', sortable: false},
                {key: 'order_number', label: 'Número Pedido', sortable: false},
                {key: 'request_date', label: 'Fecha Pedido', sortable: false},
                {key: 'order_maintenance_type_id', label: 'Tipo Mantenimiento', sortable: false},
                {key: 'order_maintenance_sub_type_id', label: 'Subtipo Mantenimiento', sortable: false},
                {key: 'amount', label: 'Gastos', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_order: 'childrenInstallationDocuments.documents_order',
            documents_by_route_order: 'childrenInstallationDocuments.documents_by_route_order',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {

        this.context = 'document-order'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                start_date: null,
                end_date: null,
                provider_id: null,
                order_number: null,
                request_date: null,
                order_maintenance_type_id: null,
                order_maintenance_sub_type_id: null,
                amount: null,
                route: this.child_installation.master_installation.installation_code + "/" + this.child_installation.gei_code + "/PEDIDOS2",
                children_installation_id: this.child_installation.id,
                observations: null,
                options: null,
                master: 0,
            }

            this.documents_order.unshift(newRow)
            this.documents_order.editedRow = newRow
        },
        addRowMaster(){
            const newRow = {
              start_date: null,
              end_date: null,
              provider_id: null,
              order_number: null,
              request_date: null,
              order_maintenance_type_id: null,
              order_maintenance_sub_type_id: null,
              amount: null,
              route: this.child_installation.master_installation.installation_code + "/" + this.child_installation.gei_code + "/PEDIDOS2",
              children_installation_id: this.child_installation.id,
              observations: null,
              options: null,
              master: 1,
            }

            this.documents_order.unshift(newRow)
            this.documents_order.editedRow = newRow
        }
    }

}
</script>