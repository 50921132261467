<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Informe Lugar de Trabajo RD 486/97 EE</h5>
                <b-button v-if="can('delete_gei_children_workplace_ee')"
                    variant="outline-success"
                    @click="addRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "
                    />
                </b-button>
            </div>

            <form-base-document
                :documents-state-data="documents_workplace_e_e"
                :documents-columns="columns"
                :documents-route="documents_by_route_workplace_e_e"
                :context="'workplace-ee'"
                :create-permission="can('create_gei_children_workplace_ee')"
                :delete-permission="can('delete_gei_children_workplace_ee')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocument from "@/views/gei/children_installations/form/tabRisks/components/base/formBaseDocument"
import {mapFields} from "vuex-map-fields"

export default {
    name: "FormContractProviderDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument

    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'spm_request_date', label: 'Fecha Solicitud SPM', sortable: false},
                {key: 'received_date', label: 'Fecha Recepción Informe', sortable: false},
                {key: 'technician', label: 'Técnico Informe', sortable: false},
                {key: 'client_delivery_date', label: 'Fecha Entrega Cliente', sortable: false},
                {key: 'provider_delivery_date', label: 'Fecha Entrega Proveedor', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapState('childrenInstallationDocuments', []),
        ...mapFields({
            documents_workplace_e_e: 'childrenInstallationDocuments.documents_workplace_e_e',
            documents_by_route_workplace_e_e: 'childrenInstallationDocuments.documents_by_route_workplace_e_e',
            child_installation: 'childrenInstallation.child_installation',
        }),
    },
    mounted() {
       this.context = 'workplace-ee'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                received_date: null,
                exam_date: null,
                provider_id: null,
                route: this.child_installation.master_installation.installation_code +"/"+ this.child_installation.gei_code + "/INFORM_RD486/EE",
                observations: null,
                options: null,
                children_installation_id: this.child_installation.id,
            }

            this.documents_workplace_e_e.unshift(newRow)
            this.documents_workplace_e_e.editedRow = newRow
        },
    }

}
</script>