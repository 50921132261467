<template>
    <div>
        <form-identification-risk-e-e/>
        <form-workplace-e-e/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import formIdentificationRiskEE
    from "@/views/gei/children_installations/form/tabRisks/components/formIdentificationRiskEE"
import formWorkplaceEE from "@/views/gei/children_installations/form/tabRisks/components/formWorkplaceEE"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocuments/getField',
    mutationType: 'masterInstallationDocuments/updateField',
})

export default {
    name: "FormGeneralDataAndRisksView",
    components: {
        formIdentificationRiskEE,
        formWorkplaceEE
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationDocuments', []),
        ...mapFields({

        }),
    },
    created() {

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>