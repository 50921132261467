<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card v-if="mountedActionsComplete">
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row sm="12">
                            <b-col sm="4">
                                <b-form-group label="Instalación Master" label-for="gei-code">
                                    <v-select
                                        v-model="selectedInstallation"
                                        class="pt-0" label="installation_code" name="installation_code"
                                        :reduce="(option) => option"
                                        :options="masterInstallations"
                                        :disabled="edit"
                                        @input="setInstallation"
                                    >
                                    <template #no-options="{ }">
                                       -
                                    </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="!edit" sm="2">
                                <segment-id-select :segment-id="child_installation.segment_id" :disabled="true"
                                                   :validation="validation_child_installation.segment_id"
                                />
                            </b-col>
                            <b-col v-if="!edit" sm="2">
                                <installation-type-id-select
                                    :installation-type-id="child_installation.installation_type_id"
                                    :validation="validation_child_installation.installation_type_id" @update="handleUpdateFields"/>
                            </b-col>
                            <b-col v-if="!edit" sm="2">
                                <date-start-flat-pickr :date-start="child_installation.start_date"
                                                       :validation="validation_child_installation.start_date"
                                                       @update="handleUpdateFields"/>
                            </b-col>
                            <b-col v-if="!edit" sm="2">
                                <date-end-flat-pickr :date-end="child_installation.end_date"
                                                     :validation="validation_child_installation.end_date"
                                                     @update="handleUpdateFields"/>
                            </b-col>

                            <b-col v-if="!edit" sm="7">
                                <installation-code-concat-input :installation-type-code="child_installation.installation_type_code"
                                                                :segment-code="child_installation.segment_code"
                                                                :installation-code="child_installation.installation_code"
                                                                :installation-id="inst_mast_num_cog_gei"
                                                                :validation="validation_child_installation.installation_code"
                                                                @update="handleUpdateFields"/>
                            </b-col>

                            <b-col v-if="edit" sm="4">
                                <b-form-group label="Instalación Hija" label-for="gei-code">
                                    <b-form-input v-model="child_installation.gei_code" autocomplete="off" />
                                </b-form-group>
                            </b-col>
                            <b-col sm="4">
                                <typology-id-select :typology-id="child_installation.typology_id"  :validation="validation_child_installation.typology_id"
                                                    @update="handleUpdateFields"/>
                            </b-col>

                            <b-col v-if="!edit" class="mr-1 ml-1 mt-2">
                                <b-row sm="12" >
                                    <b-button v-if="!edit && can('create_gei_children')" variant="outline-success" block @click="createInstallation()">
                                        Crear
                                    </b-button>
                                    <b-button v-if="edit && can('edit_gei_children')" variant="outline-success" block @click="updateInstallation()">
                                        Guardar
                                    </b-button>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-tabs>
                            <b-tab v-if="edit" active title="Datos Instalación">
                                <main-form/>
                            </b-tab>
                            <b-tab v-if="edit" title="Documentación">
                                <main-form-documents/>
                            </b-tab>
                            <b-tab v-if="edit" title="Riesgos">
                                <main-form-risks/>
                            </b-tab>
                            <b-tab v-if="edit" title="Medidas de Emergencia">
                                <main-form-emergency/>
                            </b-tab>
                            <b-tab v-if="edit" title="ACAE/Concurrencia">
                                <main-form-document-a-c-a-e/>
                            </b-tab>
                            <b-tab v-if="edit" title="Mantenimiento">
                                <main-form-document-maintenance/>
                            </b-tab>
                            <b-tab v-if="edit" title="Doc.Proy. y Man.">
                                <main-form-manual-project/>
                            </b-tab>
                            <b-tab v-if="edit" title="G. Pedidos">
                                <main-form-order/>
                            </b-tab>
                            <b-tab v-if="edit" title="OCA">
                                <main-form-oca/>
                            </b-tab>
                        </b-tabs>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {BTab, BCard, BCol, BTabs, BOverlay, BRow, BFormGroup, BButton, BFormInput} from 'bootstrap-vue'
import {mapState} from "vuex"

import {mapFields} from "vuex-map-fields"

import mainForm from "@/views/gei/children_installations/form/tabInstallationData/formView"
import SegmentIdSelect from "@/views/gei/masters_installations/form/formHeader/components/segmentIdSelect"
import installationTypeIdSelect
    from "@/views/gei/masters_installations/form/formInstallationData/components/installationTypeIdSelect"
import mainFormDocuments from "@/views/gei/children_installations/form/tabDocuments/mainFormDocuments"
import mainFormRisks from "@/views/gei/children_installations/form/tabRisks/mainFormRisks"
import mainFormEmergency from "@/views/gei/children_installations/form/tabEmergency/mainFormEmergency"
import mainFormDocumentACAE from "@/views/gei/children_installations/form/tabDocumentACAE/mainFormDocumentACAE"
import mainFormManualProject from "@/views/gei/children_installations/form/tabManualProject/mainFormManualProject"
import mainFormOrder from "@/views/gei/children_installations/form/tabOrder/mainFormOrder"
import mainFormOca from "@/views/gei/children_installations/form/tabOca/mainFormOca"
import mainFormDocumentMaintenance
    from "@/views/gei/children_installations/form/tabMaintenance/mainFormDocumentMaintenance"
import installationCodeConcatInput
    from "@/views/gei/masters_installations/form/formHeader/components/installationCodeConcatInput"
import helper from "@/resources/helpers/helpers"
import vSelect from "vue-select"
import dateEndFlatPickr from "@/views/gei/masters_installations/form/formInstallationData/components/dateEndFlatPickr"
import dateStartFlatPickr
    from "@/views/gei/masters_installations/form/formInstallationData/components/dateStartFlatPickr"

import typologyIdSelect from "@/views/gei/masters_installations/form/formInstallationData/components/typologyIdSelect"
import {CHILDREN_INSTALLATIONS_LIST, GEI_MASTERS_INSTALLATIONS_LIST} from "@/router/web/constants"

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BOverlay,
        BTab,
        BTabs,
        BFormGroup,
        BButton,
        BFormInput,
        vSelect,

        mainForm,
        SegmentIdSelect,
        installationTypeIdSelect,
        mainFormDocuments,
        mainFormRisks,
        mainFormEmergency,
        mainFormDocumentACAE,
        mainFormManualProject,
        mainFormOrder,
        mainFormOca,
        mainFormDocumentMaintenance,
        installationCodeConcatInput,
        dateEndFlatPickr,
        dateStartFlatPickr,
        typologyIdSelect
    },
    data() {
        return {
            edit: false,
            showLoading: false,
            mountedActionsComplete: false,
            segmentsList: [],
            masterInstallations: [],
            selectedInstallation: null,
            inst_mast_num_cog_gei: null,
        }
    },
    computed: {
        ...mapState('childrenInstallation', ['validation_child_installation']),
        ...mapState('masterInstallation', []),
        ...mapFields({
            child_installation: 'childrenInstallation.child_installation',
            master_installation: 'masterInstallation.master_installation',
        }),
    },
    async beforeMount() {
        this.$store.commit('childrenInstallation/RESET_STATE')
        this.$store.commit('childrenInstallation/CLEAR_VALIDATION')
        this.getMasterInstallations()
        await this.executeMountedActions()

        this.mountedActionsComplete = true
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        async executeMountedActions() {

            if (this.$route.params.id) {
                this.edit = true
                await this.getChildInstallation()
            } else {
                this.edit = false
            }
            this.$root.$refs.childrenInstallationForm = this
        },
        async getChildInstallation() {
            this.showLoading = true
            this.$store.dispatch('childrenInstallation/getChildInstallation', {
                relations: ['masterInstallation', 'masterInstallation.clientContractEE', 'masterInstallation.transferCertificate', 'masterInstallation.clientEmergency',
                    'providerContractEE', 'specificPrevention', 'identificationRiskEE', 'workplaceEE', 'emergencyPlanEE', 'documentACAE', 'documentMaintenance',
                    'documentMaintenance.inspection', 'documentMaintenance.atex', 'documentMaintenance.invigilando', 'documentMaintenance.insitu',
                    'documentManualProject', 'documentOrder', 'documentOca.inspection', 'typology'],
                id: this.$route.params.id ?? this.child_installation.id,
            })
            .then(response => {
                this.selectedInstallation = response.master_installation
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        cancel() {
            //   this.$router.push(GEI_MASTERS_INSTALLATIONS_LIST)
        },
        createInstallation() {

            this.child_installation['inst_mast_num_cog_gei'] = this.inst_mast_num_cog_gei

            this.$store.dispatch('childrenInstallation/createChildrenInstallation', this.child_installation)
            .then(response => {
                this.child_installation.id = response.id

                helper.showToast('Instalación hija creada correctamente!', 'CheckIcon', 'success')
                this.$router.replace(CHILDREN_INSTALLATIONS_LIST)
            })
            .catch(error => {
                helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        updateInstallation() {
            this.$store.dispatch('childrenInstallation/updateChildrenInstallation', this.child_installation)
            .then(response => {
                helper.showToast('Instalación hija editada correctamente!', 'CheckIcon', 'success')
            })
            .catch(error => {
                helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        getMasterInstallations(){
            this.$store.dispatch('masterInstallation/getMasterInstallationsList', {relations: ['segment']})
            .then(response => {
                this.masterInstallations = response

            })
            .catch(error => {
                helper.showToast('Error al obtener instalaciones masters', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        setInstallation() {

          var splitInstallationCode = this.selectedInstallation.installation_code.split('_')

          if (splitInstallationCode.length >= 3) {
            var valueNum = splitInstallationCode[2]

            this.inst_mast_num_cog_gei = valueNum
          }
            if (this.selectedInstallation ) {
                this.child_installation.master_installation_id = this.selectedInstallation.id
                this.child_installation.segment_id = this.selectedInstallation.segment_id
                this.child_installation.segment_code = this.selectedInstallation.segment.code
            } else {
                this.child_installation.segment_id = null
                this.child_installation.master_installation_id = null
                this.child_installation.segment_code = null
            }
        },
        handleUpdateFields(updatedFields) {

            this.child_installation = {...this.child_installation, ...updatedFields}
        },

    },
}
</script>
