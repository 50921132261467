<template>
    <div>
        <form-maintenance/>
        <form-inspection/>
        <form-invigilando/>
        <form-in-situ/>
        <form-atex/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import formMaintenance from "@/views/gei/children_installations/form/tabMaintenance/components/formMaintenance"
import formInspection from "@/views/gei/children_installations/form/tabMaintenance/components/formInspection"
import formInvigilando from "@/views/gei/children_installations/form/tabMaintenance/components/formInvigilando"
import formInSitu from "@/views/gei/children_installations/form/tabMaintenance/components/formInSitu"
import formAtex from "@/views/gei/children_installations/form/tabMaintenance/components/formAtex"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocuments/getField',
    mutationType: 'masterInstallationDocuments/updateField',
})

export default {
    name: "FormGeneralDataAndRisksView",
    components: {
        formMaintenance,
        formInspection,
        formInvigilando,
        formInSitu,
        formAtex
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationDocuments', []),
        ...mapFields({

        }),
    },
    created() {

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>