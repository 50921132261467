<template>
    <div>
        <b-form-group label-for="dias" class="mt-2">
            <b-form-group class="form-check form-check-inline">
                <b-form-checkbox v-model="vehiclesCompetition" :value="1" :disabled="disabled">
                    Transito de vehiculo
                </b-form-checkbox>
            </b-form-group>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormCheckbox,
    },
    props: {
        vehiclesCompetition: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localVehiclesCompetition: this.vehiclesCompetition,
        }
    },
    watch: {
        vehiclesCompetition(newVal) {
            this.localVehiclesCompetition = newVal
            this.$emit('update', { vehicles_competition: this.localVehiclesCompetition })
        },
    },
}
</script>
